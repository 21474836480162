import instance from './base';

export async function getVersion() {
  const rsp = await instance.get('/posfishing/setting/version');
  return rsp.data;
}

export async function setVersion(value) {
  const rsp = await instance.put('/posfishing/setting/version', value);
  return rsp.data;
}
