<template lang="pug">
.version-setting.flex.column.p-20.gap-20.overflow-overlay
  .row.flex.align-center.gap-8
    .title 當前版本
    .version {{ currentVersion }}
  .row.flex.align-center.gap-8
    .title 最新版本
    .version {{ version }}
  .row.flex.align-center(v-if="currentVersion !== version")
    .link-text.clickable(@click="goLink") 下載更新
</template>

<script>
import { mapMutations } from 'vuex';
import { getVersion } from '@/api/setting';

export default {
  data() {
    return {
      currentVersion: '1.0.0',
      version: '',
      url: '',
    };
  },
  methods: {
    ...mapMutations(['setHeaderTitle']),
    loadVersion() {
      this.$execWithLoading(async () => {
        const rsp = await getVersion();
        const versionMap = rsp.data;
        const versions = Object.keys(versionMap);
        versions.sort();
        [this.version] = versions;
        this.url = versionMap[this.version];
      });
    },
    goLink() {
      window.open(this.url, '_blank');
    },
  },
  mounted() {
    this.loadVersion();
    this.setHeaderTitle('版本資訊');
  },
};
</script>

<style lang="scss" scoped>

</style>
